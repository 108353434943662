
.profile-pic {
  position: absolute;
  top: -70px;
  background: #fff;
  border-radius: 100%;
  padding: 20px;
  border: 6px solid whitesmoke;
  box-sizing: content-box;
}

.achievement-block {
  border-bottom: 1px solid #e4e4e4;
  padding: 20px 0;
  &:last-child {
    border-bottom: 0;
  }
}
